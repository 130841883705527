import React, { useState } from 'react';
import PageHeader from '../../tenant_portal/PageHeader';
import Text from '../../tenant_portal/components/Text';
import PortalButton from '../../components/PortalButton';
import { useLoaderData, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import {
  PreloadedQuery,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';
import { RequestLinkLoginMutation$data } from './__generated__/RequestLinkLoginMutation.graphql';
import SubmitSpinner from '../../components/SubmitSpinner';
import SuccessPage from '../../tenant_portal/SuccessPage';
import { ErrorType } from '../../components/Types';
import {
  formatErrorMessages,
  formatProviderName,
  filterErrorsByPath,
} from '../../components/Tools';
import layoutStyles from '../../../../stylesheets/components/layout.module.scss';
import textStyles from '../../../../stylesheets/components/text.module.scss';
import type {
  RequestLinkQuery as RequestLinkQueryType,
  RequestLinkQuery$data as RequestLinkQueryResponse,
} from './__generated__/RequestLinkQuery.graphql';
import classNames from 'classnames';
import LinkIcon from './LinkIcon';
import Alert from '../../components/Alert';

export const RequestLinkQuery = graphql`
  query RequestLinkQuery($token: String!) {
    oauthLinkActivation(token: $token) {
      oauthProvider {
        email
        provider
      }
    }
  }
`;

export const LoginRequestLinkMutation = graphql`
  mutation RequestLinkLoginMutation($input: LoginRequestLinkInput!) {
    loginRequestLink(input: $input) {
      errors {
        path
        messages
      }
    }
  }
`;

const RequestLink = () => {
  const [emailField, setEmailField] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { oauthLinkActivationToken } = useParams();
  const [commitMutation, isMutationInFlight] = useMutation(
    LoginRequestLinkMutation
  );
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const baseErrors = filterErrorsByPath(errors);
  const formattedBaseErrorMessages = formatErrorMessages(baseErrors, '');

  const handleSubmit = () => {
    commitMutation({
      variables: {
        input: {
          email: emailField,
          oauthLinkActivationToken: oauthLinkActivationToken,
        },
      },
      onCompleted: (response) => {
        const responseData = response as RequestLinkLoginMutation$data;
        console.log('completed! response: ', responseData);
        if (!responseData || !responseData.loginRequestLink) {
          setErrors([
            {
              path: 'base',
              messages: [
                'There was an error. Please contact Property Matrix support for assistance.',
              ],
            },
          ]);
          return;
        }
        if (
          responseData.loginRequestLink.errors &&
          responseData.loginRequestLink.errors.length > 0
        ) {
          console.log('error: ', responseData.loginRequestLink.errors);
          setErrors(responseData.loginRequestLink.errors as ErrorType[]);
          return;
        }
        setIsEmailSent(true);
      },
      onError: (error) => {
        setErrors([
          {
            path: 'base',
            messages: [
              'There was an error. Please contact Property Matrix support for assistance.',
            ],
          },
        ]);
        console.error(error);
      },
    });
  };

  const loaderQueryReference = useLoaderData() as PreloadedQuery<
    RequestLinkQueryType,
    RequestLinkQueryResponse
  >;

  const [queryReference] = useQueryLoader<RequestLinkQueryType>(
    RequestLinkQuery,
    loaderQueryReference
  );

  const loginActivationData = usePreloadedQuery<RequestLinkQueryType>(
    RequestLinkQuery,
    queryReference as PreloadedQuery<
      RequestLinkQueryType,
      RequestLinkQueryResponse
    >
  );

  const oauthProviderData =
    loginActivationData.oauthLinkActivation?.oauthProvider;

  if (!oauthProviderData) {
    return <div>There was an error.</div>;
  }

  const formattedProviderName = formatProviderName(oauthProviderData.provider);

  if (isMutationInFlight)
    return <SubmitSpinner spinnerText={'Sending email...'} />;

  if (isEmailSent)
    return (
      <SuccessPage
        successText="Email sent"
        successSubText="Please check your email for a link to link your accounts."
      />
    );

  return (
    <div className={layoutStyles.fullWidth}>
      {baseErrors.length > 0 && (
        <Alert severity="error">{formattedBaseErrorMessages}</Alert>
      )}
      <LinkIcon />
      <PageHeader title={`Link Your ${formattedProviderName} Email`} />
      <span
        className={classNames(
          layoutStyles.block,
          textStyles.fs16,
          textStyles.colorSecondary,
          layoutStyles.mb20
        )}
      >
        Oops! We couldn&apos;t find {oauthProviderData.email} in our system.
        Looks like the email you use with us is different from your{' '}
        {formattedProviderName} email.
      </span>
      <span
        className={classNames(
          layoutStyles.block,
          textStyles.fs16,
          textStyles.colorSecondary,
          layoutStyles.mb30
        )}
      >
        No worries, though! Just connect the email you&apos;re using with us to
        your {formattedProviderName} account, and you&apos;ll be all set for
        easy {formattedProviderName} sign-ins in the future.
      </span>
      <Text
        field={{
          label: 'Email address',
          name: 'email',
          type: 'email',
          value: emailField,
          autocomplete: 'email',
        }}
        mode="edit"
        onChange={(fieldName, value) => setEmailField(value?.toString() || '')}
        errors={filterErrorsByPath(errors, ['email'])}
      />
      <PortalButton onClick={handleSubmit}>Link Accounts</PortalButton>
    </div>
  );
};

export default RequestLink;
